import React from 'react';
import styled from 'styled-components';
import { H1, H3 } from '@/components/Typography';
import viewport1Img from '../../assets/img/serviceHubVp1.png';
import FirstViewport from '@/components/FirstViewport';
import TickedItems from '@/components/TickedItems';
import ButtonDefault from '@/components/ButtonDefault';
import CarouselCard from '@/components/CarouselCard';
import Section from '@/components/Section';
import Banner from '@/components/Banner';
import bannerBg from '../../assets/img/b2bBannerBg.png';
import { ServiceHubPageContent } from '@/pages/service-management';

const FirstVpWrapper = styled.div`
  background: linear-gradient(118.47deg, #f8f9fa 3.57%, #e4e6f0 100%);
`;

const SecondViewport = styled(Section)``;

const ThirdViewport = styled(Section)`
  background-color: #fff;
`;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
`;

const StyledH1 = styled(H1)`
  margin-bottom: 2rem;
`;

const CenteredH3 = styled(H3)`
  text-align: center;
`;

const BgViewportWrapper = styled.div`
  background: linear-gradient(
    358.95deg,
    #e7edf3 1.49%,
    rgba(255, 255, 255, 1) 97.48%
  );
`;

const StyledButtonDefault = styled(ButtonDefault)`
  margin-top: 2rem;
`;

interface ServiceHubProps {
  content: ServiceHubPageContent;
}

const ServiceHubContainer: React.FunctionComponent<ServiceHubProps> = ({
  content: { viewport1, viewport2, viewport3 },
}) => (
  <>
    <FirstVpWrapper>
      <FirstViewport img={viewport1Img}>
        <StyledH1>
          {viewport1.title1}
          <span className="accent-text"> {viewport1.title2}</span>
          {` `}
          {viewport1.title3}
          {` `}
          <span className="accent-text"> {viewport1.title4} </span>
          {` `}
          {viewport1.title5}
        </StyledH1>
        <TickedItems items={viewport1.tickedDescription} />
        <StyledButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </StyledButtonDefault>
      </FirstViewport>
    </FirstVpWrapper>

    <BgViewportWrapper>
      <SecondViewport>
        <CenteredH3>{viewport2.title}</CenteredH3>
        <CardWrapper>
          {viewport2.benefitCards.map((item) => (
            <CarouselCard
              key={item.title}
              flexBasis="25%"
              marginBottom="2rem"
              title={item.title}
              text={item.description}
              cardImgSrc={item.imageSrc}
            />
          ))}
        </CardWrapper>
      </SecondViewport>
    </BgViewportWrapper>

    <ThirdViewport>
      <Banner
        bgSrc={bannerBg}
        label={viewport3.bannerLabel}
        description={viewport3.bannerDescription}
        buttonLabel={viewport3.button.label}
        buttonUrl={viewport3.button.url}
      />
    </ThirdViewport>
  </>
);

export default ServiceHubContainer;
