import React from 'react';
import Main from '../containers/Layout';
import serviceHubContent from '../../content/pages/service-management.yml';
import ServiceHubContainer from '@/containers/toolkit/ServiceHubContainer';

export interface ServiceHubPageContent {
  viewport1: {
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    title5: string;
    tickedDescription: { item: string }[];
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    title: string;
    benefitCards: {
      title: string;
      description: string;
      imageSrc: string;
    }[];
  };
  viewport3: {
    bannerLabel: string;
    bannerDescription: string;
    button: { label: string; url: string };
  };
}

const ServiceHub: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3 } =
    serviceHubContent as unknown as ServiceHubPageContent;
  return (
    <Main>
      <ServiceHubContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
        }}
      />
    </Main>
  );
};

export default ServiceHub;
